import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import * as styles from './blog-hero.module.css'

const BlogHero = ({ image, title, content, postDate, publishDate, timeToRead }) => (
    <div className={styles.hero}>
        <div className={styles.details}>
            <h1 className={styles.title}>{title}</h1>
            {content && (
                <div className={styles.content}>{renderRichText(content)}</div>
            )}
            <span className={styles.meta}>
                <time dateTime={postDate}>{publishDate}</time> –{' '}
                {timeToRead} minute read
            </span>
        </div>
        {image && (
            <GatsbyImage className={styles.image} alt={title} image={image} />
        )}
    </div>
)

export default BlogHero
